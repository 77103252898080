import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Card from 'reusecore/src/elements/Card';
import Image from 'reusecore/src/elements/Image';
import FeatureBlock from '../../../components/FeatureBlock';
import Container from '../../../components/UI/Container';
import VisitorSectionWrapper, { SectionObject } from './visitor.style';

import ImageBg from '../../../assets/image/saas/visitor_bg.png';

const VisitorSection = ({
  title,
  titleText,
  description,
  descriptionText,
  textArea,
  imageWrapper,
  rightText,
  btnStyle,
  image,
}) => {
  return (
    <VisitorSectionWrapper id="visitorSection">
      <SectionObject rightText={rightText}>
        <Card className="objectWrapper" {...imageWrapper}>
          <Zoom>
            <Image src={ImageBg} alt="Background" />
          </Zoom>
          <Card className="dashboardWrapper" {...imageWrapper}>
            <Fade
              {...(rightText
                ? { right: false, left: true }
                : { right: true, left: false })}
            >
              <Image {...image} />
            </Fade>
          </Card>
        </Card>
      </SectionObject>
      <Container>
        <Box
          {...textArea}
          {...(rightText ? { ml: [0, 0, '58%'] } : { mr: [0, 0, '58%'] })}
        >
          <FeatureBlock
            title={<Heading content={titleText} {...title} />}
            description={<Text content={descriptionText} {...description} />}
          />
        </Box>
      </Container>
    </VisitorSectionWrapper>
  );
};

VisitorSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

VisitorSection.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
  },
  imageWrapper: {
    boxShadow: 'none',
    width: '100%',
  },
  title: {
    fontSize: ['20px', '26px', '26px', '36px', '48px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default VisitorSection;
