import React, { useEffect } from 'react';
import uuid from 'uuid/v4';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import FeatureBlock from '../../../components/FeatureBlock';
import Container from '../../../components/UI/Container';
import Particles from '../Particle';
import { useCookie } from '@use-hook/use-cookie';
import Input from 'reusecore/src/elements/Input';
import { socialEuroOutline } from 'react-icons-kit/ionicons/socialEuroOutline';
import { Icon } from 'react-icons-kit';
import axios from 'axios';

import BannerWrapper, {
  BannerObject,
  InputWrapper,
  InputError
} from './bannerSection.style';

import * as yup from 'yup';

import { useForm } from 'react-hook-form';

import BannerObject1 from '../../../assets/image/saas/banner/bannerObject1.png';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle
}) => {
  return (
    <BannerWrapper id="banner_section">
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            {/* <DiscountLabel>
              <Text content="25% Discount" {...discountAmount} />
              <Text content="on every first project " {...discountText} />
            </DiscountLabel> */}
            <FeatureBlock
              title={
                <Heading content="Confronta i POS e risparmia" {...title} />
              }
              description={
                <Text
                  content="Confrontiamo gratuitamente le migliori offerte POS per permetterti di scegliere facilmente la più adatta alla
                  tua attività. Puoi risparmiare fino a 9.000 € l’anno!"
                  {...description}
                />
              }
            />
          </Box>
          <Box className="col" {...col}>
            <Form btnStyle={btnStyle} />
          </Box>
        </Box>
      </Container>
      <BannerObject>
        <div className="objectWrapper">
          <Image src={BannerObject1} alt="BannerObject1" />
          <div className="dashboardWrapper">
            {/* <Image src={BannerObject2} alt="BannerObject2" /> */}
          </div>
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '100%', '50%', '45%']
  },
  title: {
    fontSize: ['42px', '42px', '40px', '55px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
    as: 'h1'
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: ['20px', '20px', '0']
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg'
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#5167db',
    ml: '18px'
  },
  discountAmount: {
    fontSize: '14px',
    color: '#eb4d4b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#0f2137',
    mb: 0,
    as: 'span',
    fontWeight: 500
  }
};

export default BannerSection;

const SignupSchema = yup.object().shape({
  amount: yup.string().required()
});

const Form = ({ btnStyle }) => {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: SignupSchema,
    nativeValidation: false
  }); // initialise the hook

  // eslint-disable-next-line
  const [amount, setAmount] = useCookie('amount', '');
  const [UUID, setUUID] = useCookie('UUID');

  useEffect(() => {
    if (UUID) return;

    setUUID(uuid());
  }, [setUUID, UUID]);

  const onSubmit = ({ amount }) => {
    let rightAmount = Math.round(amount / 100) * 100;
    if (rightAmount > 99999999) {
      rightAmount = 99999999;
    }

    setAmount(rightAmount);

    if (
      typeof window !== 'undefined' &&
      window.ga &&
      typeof window.ga.getAll === 'function'
    ) {
      try {
        const tracker = window.ga.getAll()[0];
        tracker.send('event', {
          eventCategory: 'Step 1',
          eventAction: 'click'
        });
      } catch (e) {}
    }

    axios
      .post(`${process.env.GATSBY_BACKEND_URL}/hooks/catch`, {
        emailAddress: '',
        amount: rightAmount,
        eventType: 'confronta_pressed',
        date: new Date().toISOString(),
        uuid: UUID
      })
      .catch(err => {
        console.log(err);
      });

    window.location.href = `/step-2/?q=${rightAmount}&t=a&ref_type=hp_amount`;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper error={errors.amount}>
        <Input
          placeholder="Il tuo incasso annuo con POS"
          name="amount"
          inputType="number"
          iconPosition="right"
          icon={<Icon icon={socialEuroOutline} size={30} />}
          ref={register}
          fontSize={['25px']}
          min={0}
          autofocus="autofocus"
        />
      </InputWrapper>
      <InputError>
        {errors.amount &&
          "Inserisci l'importo che stimi di transare. Se non lo sai usa gli incassi dell'ultimo anno."}
      </InputError>
      <Button
        id="step-1"
        title="SCOPRI IL POS PIÙ CONVENIENTE"
        {...btnStyle}
        type="submit"
        pulse
      />
    </form>
  );
};
