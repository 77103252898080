import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper
} from 'common/src/containers/Saas/saas.style';
import Navbar from 'common/src/containers/Saas/Navbar';
import BannerSection from 'common/src/containers/Saas/BannerSection';
import FeatureSection from 'common/src/containers/Saas/FeatureSection';
import HowItWorksSection from 'common/src/containers/Saas/TimelineSection';
import VisitorSection from 'common/src/containers/Saas/VisitorSection';
import Footer from 'common/src/containers/Saas/Footer';
import PartnerSection from 'common/src/containers/Saas/PartnerSection';
import TrialSection from 'common/src/containers/Saas/TrialSection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';

export default ({ location }) => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO
          title="Confronta le offerte POS e risparmia"
          description="Calcola online costi e commissioni delle migliori offerte POS e scegli la più conveniente. Puoi risparmiare fino a 9.000€ l’anno!"
          meta={[
            {
              property: 'og:url',
              content: `${process.env.GATSBY_HOST}${location.pathp}`
            },
            {
              property: 'og:image',
              content: `${process.env.GATSBY_HOST}/images/og:image.jpeg`
            },
            {
              property: 'og:image:width',
              content: '1200'
            },
            {
              property: 'og:image:height',
              content: '630'
            }
          ]}
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <HowItWorksSection />
          <FeatureSection />
          <VisitorSection
            image={{
              src: '/images/comodita_contactless.svg',
              alt:
                'La comodità e sicurezza dei pagamenti elettronici contactless'
            }}
            titleText="Comodità e sicurezza"
            descriptionText="Con la tecnologia contactless, bastano pochi secondi per incassare un pagamento con carta e ti dimentichi delle code alla cassa. I pagamenti elettronici sono molto più sicuri di un pagamento in contanti. Puoi dire addio a contraffazione, costo di gestione del contante e rischio furti."
          />
          <VisitorSection
            image={{
              src: '/images/pos.svg',
              alt: 'Grazie al POS attiri più clienti'
            }}
            titleText="Attira più clienti"
            descriptionText="I clienti che non possono utilizzare da te la propria carta potrebbero rinunciare all’acquisto o effettuarlo presso un tuo concorrente."
            rightText
          />
          <VisitorSection
            image={{
              src: '/images/informazioni.svg',
              alt:
                'Le informazioni a disposizione di un esercente che incassa con il POS'
            }}
            titleText="Un mare di informazioni"
            descriptionText="Tutti i principali fornitori di POS ti consentono di accedere ad app o gestionali che ti forniscono dati preziosissimi sui tuoi ricavi, sulle performance della tua attività e sui tuoi clienti e le loro abitudini di spesa."
          />
          {/* <ServiceSection /> */}
          {/* <PricingSection /> */}
          {/* <TestimonialSection /> */}
          <PartnerSection />
          {/* <FaqSection /> */}
          <TrialSection
            titleText="Scegli il POS più conveniente!"
            contentText="È completamente gratuito. Nessun vincolo. 3 minuti e pochi dati per risparmiare."
          />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
